import type { ReactElement } from 'react';
import { Feedback } from '../../../organisms/feedback';
import { MenuContainer, MenuPopup } from '../../../molecules/menu';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import type { HeaderRightComponentsProps } from './types';
import styles from './headerRightComponents.module.scss';
import { haveAccess } from '@uxu/utils/frontend/auth';

export function HeaderRightComponents({ switchModalButtonText, modalAlignment, customBtn }: HeaderRightComponentsProps): ReactElement {
  const config = useSiteConfig();

  // Check access using haveAccess
  const { isAccess: isLoggedIn } = haveAccess();

  return (
    <>
      {/* Feedback Component */}
      <Feedback modalAlignment={modalAlignment} switchModalButtonText={switchModalButtonText} />

      {/* Custom Buttons */}
      {customBtn?.map(({ url, title, className }, index) => (
        <a key={index} href={url} title={title} className={`btn ${className}`}>
          {title}
        </a>
      ))}

      {/* Authentication Buttons */}
      {config.site.authEnabled &&
        (isLoggedIn ? (
          <MenuContainer>
            <button className="btn primary" type="button">
              konto
            </button>
            <MenuPopup className={styles.userDashboard}>
              <a className="btn error" href="/api/logto/sign-out">
                wyloguj
              </a>
            </MenuPopup>
          </MenuContainer>
        ) : (
          <a className="btn primary" href="/api/logto/sign-in">
            zaloguj się
          </a>
        ))}
    </>
  );
}

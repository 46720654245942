import { useAppSelector } from '@uxu/design-system/store';

export function haveAccess(
  action?: 'create' | 'read' | 'update' | 'delete',
  services?: string[],
): { isLoadingData: boolean; isAccess: boolean; isAuthenticated: boolean } {
  const auth = useAppSelector((state) => state.auth);
  const userRoles = auth.claims?.roles || [];
  const isLoggedIn = Boolean(auth?.isAuthenticated);

  // If no action is provided, it's enough for the user to be logged in.
  if (!action) {
    return { isLoadingData: auth.isLoadingData, isAccess: isLoggedIn, isAuthenticated: isLoggedIn };
  }

  // Check if the user has access to the specified action on the given services
  if (!services || services.length === 0) {
    // If the action is provided but no services are specified, assume no access.
    return { isLoadingData: auth.isLoadingData, isAccess: false, isAuthenticated: isLoggedIn };
  }

  // Helper function: checks if the user's roles grant access to the specified action for a given service
  const canAccessService = (service: string): boolean => {
    // If the user has the "all:admin" role, they have access to everything regardless of action or service.
    if (userRoles.includes('all:admin')) {
      return true;
    }

    // Check for roles related to the specific service:
    // - <service>:moderator grants full access to this service
    // - <service>:read grants access only to the "read" action
    // If the user has none of these roles, deny access.
    const serviceModeratorRole = `${service}:moderator`;
    const serviceReadRole = `${service}:read`;

    // If the user is a service moderator, they have full access (create, read, update, delete)
    if (userRoles.includes(serviceModeratorRole)) {
      return true;
    }

    // If the user has <service>:read, check if the action is "read"
    if (userRoles.includes(serviceReadRole) && action === 'read') {
      return true;
    }

    // If other roles are required, extend the logic here in the future.
    // For now, no match -> return false
    return false;
  };

  return {
    isLoadingData: auth.isLoadingData,
    isAccess: services.some((service) => canAccessService(service)),
    isAuthenticated: isLoggedIn,
  };
}

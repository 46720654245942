/* eslint-disable react/jsx-no-leaked-render -- all ok */
import {useRef, useState, useCallback} from 'react';
import type {ReactElement, KeyboardEvent, MouseEvent} from 'react';
import classnames from 'classnames';
import {useForm} from "react-hook-form";
import {Search} from 'react-feather';
import {useSiteConfig} from '../../../../hooks/useSiteConfig';
import {Modal} from '../../../atoms/modal';
import {KeyboardShortcut} from '../../../atoms/keyboardShortcut';
import {SearchSuggestionsModal} from './components/SearchSuggestionsModal';
import styles from './searchEngineInModal.module.scss';

export function SearchEngineInModal({className }: {  className?: string }): ReactElement {
    const {client} = useSiteConfig();
    const {register, watch} = useForm<{ search: string }>();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const searchQuery: string = watch('search');


    const toggleModal = useCallback((): void => {
        setIsOpenModal(prevState => !prevState);
    }, []);

    const handleClickOutside = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            toggleModal();
        }
    }, [toggleModal]);

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter' || event.key === ' ') {
            toggleModal();
        }
    }, [toggleModal]);

    return (
        <>
            <div
                className={classnames(styles.wrapper, className)}
                onClick={toggleModal}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
            >
                <Search size={18}/>
                <p>Szukaj...</p>
                {client.platform.isDesktop && (
                    <KeyboardShortcut
                        callback={toggleModal}
                        className={styles.shortcut}
                        command
                    >
                        K
                    </KeyboardShortcut>
                )}
            </div>
            <Modal
                className={styles.modal}
                onClick={handleClickOutside}
                onClose={toggleModal}
                open={isOpenModal}
                renderDirectlyInBody
            >
                <SearchSuggestionsModal
                    isAwaitingApiResponse={false}
                    ref={modalRef}
                    register={register}
                    searchQuery={searchQuery}
                    searchResults={[]}
                    setIsOpenModal={setIsOpenModal}
                />
            </Modal>
        </>
    );
}

import { useContext } from 'react';
import type { SiteConfig } from '@uxu/utils/common/types';
import { SiteConfigContext } from '../providers/provider.configSite';

export function useSiteConfig(): SiteConfig {
    const context = useContext(SiteConfigContext);
    if (!context) {
        throw new Error('useSiteConfig must be used within a SiteConfigProvider');
    }
    return context;
}